import React from "react";
import { Link } from "react-scroll";
import { useDispatch } from "react-redux";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import { setAppointment } from "../../../redux/actions/contact.action";

import { Button } from "../../../components/commons";
import AnimateText from "../../../components/animate-text";
import MobileFamilyMeditation from "./mobile-family-meditation";

import FamilyMediationImage from "../../../assets/images/family-mediation.png";
import LineSVG from "../../../assets/svg/line.svg";

import "./index.scss";

const FamilyMediation = () => {
  const dispatch = useDispatch();

  return (
    <div className="family-mediation">
      <div className="family-mediation__desktop">
        <GridWrap>
          <GridColumn px={["xl"]}>
            <GridRow className="family-mediation__wrapper">
              <GridColumn width={12}>
                <GridColumn width={6} className="family-mediation__inner">
                  <div className="family-mediation__content">
                    <div className="family-mediation__title cross-title">
                      <h2 className="roman-number">II.</h2>
                      <div className="title">
                        <h2>
                          Familiale
                          <br />
                          bemiddeling
                        </h2>
                        <img src={LineSVG} alt="line-svg" />
                      </div>
                    </div>

                    <div className="family-mediation__description">
                      <p>
                        Zowel de wetgever als de rechtbank geven meer en meer de
                        voorrang aan familiale bemiddeling in plaats van lange
                        (scheidings)procedures. De erkende familiale
                        bemiddelaars van het scheidingshuis gaan samen met de
                        partijen op zoek naar een akkoord waar beide ex-partners
                        zich goed bij voelen.
                      </p>
                      <p>
                        Minder procedures, minder kosten en een beter gevoel
                        achteraf.
                      </p>

                      <GridRow className="family-mediation__cta" align="center">
                        <a href="/family-mediation/">
                          <AnimateText label="Lees meer" />
                        </a>

                        <a
                          href="https://calendly.com/info-22976"
                          target="_blank"
                        >
                          <Button>Maak een afspraak</Button>
                        </a>
                      </GridRow>
                    </div>
                  </div>
                </GridColumn>

                <div className="family-mediation__background">
                  <img src={FamilyMediationImage} alt="family-background-img" />
                </div>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridWrap>
      </div>

      <MobileFamilyMeditation />
    </div>
  );
};

export default FamilyMediation;
