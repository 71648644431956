export const Types = {
  SET_APPOINTMENT: "SET_APPOINTMENT",
  SET_HOME_CONTACT_SECTION: "SET_HOME_CONTACT_SECTION",
};

export const setAppointment = (data) => ({
  type: Types.SET_APPOINTMENT,
  payload: data,
});

export const setHomeContactSection = (data) => ({
  type: Types.SET_HOME_CONTACT_SECTION,
  payload: data,
});
