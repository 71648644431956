import React, { useEffect, useState } from "react";
import moment from "moment";
import { components } from "react-select";
import { useSelector } from "react-redux";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import CustomCalendar from "../calendar";
import { sendEmail } from "../../utils/email";
import { Button, Checkbox, Input, TextArea, Select } from "../commons";

import LineSVG from "../../assets/svg/line.svg";
import ClockSVG from "../../assets/svg/clock.svg";
import UserGroupSVG from "../../assets/svg/user-group.svg";

import "./index.scss";

import { personOptions, timeOptions } from "./options";

const Contact = () => {
  const contact = useSelector((state) => state.contact);
  const [appointmentTime, setAppointmentTime] = useState("");
  const [appointmentPerson, setAppointmentPerson] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [error, setError] = useState("");

  const [subscribe, setSubscribe] = useState(false);
  const [showCalendar, setShowCalendar] = useState(contact.isAppointment);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    setShowCalendar(contact.isAppointment);
  }, [contact]);

  const handleChange = (event, type) => {
    event.preventDefault();

    if (type === "name") {
      setContactForm({
        ...contactForm,
        name: event.target.value,
      });
    } else if (type === "email") {
      setContactForm({
        ...contactForm,
        email: event.target.value,
      });
    } else if (type === "message") {
      setContactForm({
        ...contactForm,
        message: event.target.value,
      });
    }
  };

  const resetForm = () => {
    console.log("resetform");
    setContactForm({
      name: "",
      email: "",
      message: "",
    });

    setError("");
  };

  const handleSubmit = () => {
    if (validateEmail(contactForm.email)) {
      let emailAppointmentPerson = "";
      switch (appointmentPerson) {
        case "Wendy":
          emailAppointmentPerson = "wendy@hetscheidingshuis.be";
          break;
        case "Sara":
          emailAppointmentPerson = "sara@hetscheidingshuis.be";
          break;
        default:
          emailAppointmentPerson = "sofie@hetscheidingshuis.be";
      }
      let template_params = {
        name: contactForm.name,
        email: contactForm.email,
        message: contactForm.message,
        appointmentPerson: appointmentPerson,
        emailAppointmentPerson: emailAppointmentPerson,
      };

      if (showCalendar) {
        template_params = {
          ...template_params,
          appointmentDate: `${moment(appointmentDate).format(
            "DD-MM-yyyy"
          )} ${appointmentTime}`,
        };
      }
      sendEmail(template_params);
      setSubscribe(true);
      resetForm();
    } else {
      setError("Gelieve alle velden correct in te vullen");
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img
              src={UserGroupSVG}
              alt="user-group"
              style={{ position: "absolute", left: 6 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const TimeValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img
              src={ClockSVG}
              alt="clock-icon"
              style={{ position: "absolute", left: 6 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const ButtonText = () => {
    if (subscribe) return "Verzonden";
    else {
      // return showCalendar ? "Maak een afspraak" : "Verzenden";
      return "verzenden";
    }
  };

  return (
    <div className="contact-us" id="contact-us">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <div className="our-team__title cross-title">
            <div className="title">
              <h2>
                {/* {showCalendar ? "Maak een afspraak" : "Contact"} */}
                Contact
              </h2>
              <img src={LineSVG} alt="line-svg" />
            </div>
          </div>
          <span className="contact-us__error">{error}</span>
          <GridRow className="contact-us__wrapper" justify="center" wrap="wrap">
            <GridColumn width={[12, 12, 12, showCalendar ? 6 : 12]}>
              <Input
                label="Naam"
                value={contactForm.name}
                onChange={(e) => handleChange(e, "name")}
              />
              <Input
                label="Email adres"
                value={contactForm.email}
                onChange={(e) => handleChange(e, "email")}
              />
              <div className="person-select">
                <Select
                  options={personOptions}
                  placeholder="Selecteer uw deskundige"
                  valueContainer={ValueContainer}
                  style={{ zIndex: "100" }}
                  onChange={(value) => setAppointmentPerson(value.label)}
                />
              </div>
              {/* {!contact.isHomeContact && (
                <Select
                  options={timeOptions}
                  placeholder="Om hoe laat wilt u afspreken?"
                  valueContainer={TimeValueContainer}
                  onChange={(value) => setAppointmentTime(value.label)}
                />
              )} */}
              <TextArea
                row="5"
                value={contactForm.message}
                onChange={(e) => handleChange(e, "message")}
              />
              {/* {contact.isHomeContact && (
                <Checkbox
                  label="Maak een afspraak"
                  onClick={() => setShowCalendar(!showCalendar)}
                  checked={showCalendar}
                />
              )} */}

              {/* {!showCalendar && (
                <div className="cta-btn">
                  <Button fullWidth onClick={handleSubmit} hasIcon={subscribe}>
                    {ButtonText()}
                  </Button>
                </div>
              )} */}
              <Button fullWidth onClick={handleSubmit} hasIcon={subscribe}>
                {ButtonText()}
              </Button>
            </GridColumn>

            {/* <GridColumn
              className={`calendar-wrap ${
                showCalendar && "calendar-wrap--open"
              }`}
              width={[12, 12, 12, showCalendar ? 5 : 12]}
              offset={["none", "none", "none", showCalendar ? 1 : "none"]}
            >
               <GridColumn className="calendar-wrap__inner">
                <CustomCalendar
                  onChange={setAppointmentDate}
                  value={appointmentDate}
                  locale="nl"
                />

                {contact.isHomeContact && (
                  <GridRow className="contact-us__expert" align="center">
                    <Select
                      options={timeOptions}
                      placeholder="Om hoe laat wilt u afspreken?"
                      valueContainer={TimeValueContainer}
                      onChange={(value) => setAppointmentTime(value.label)}
                    />
                  </GridRow>
                )}

                {showCalendar && (
                  <div className="cta-btn">
                    <Button
                      fullWidth
                      onClick={handleSubmit}
                      hasIcon={subscribe}
                    >
                      {ButtonText()}
                    </Button>
                  </div>
                )}
              </GridColumn>
            </GridColumn> */}
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default Contact;
