import React from "react";

import "./index.scss";

const TextArea = ({ value, ...props }) => {
  return (
    <div className="textarea-wrapper">
      <textarea value={value} {...props} />
    </div>
  );
};

export default TextArea;
