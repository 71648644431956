import React from "react";
import { Link } from "react-router-dom";
import { GridColumn, GridRow } from "emotion-flex-grid";

import {Button} from "../../../components/commons";

import FamilyMediationImage from "../../../assets/images/family-mediation.png";
import LineSVG from "../../../assets/svg/line.svg";

const MobileFamilyMeditation = () => {
  return (
    <div className="family-mediation__mobile">
      <div className="family-mediation__background">
        <img src={FamilyMediationImage} alt="family-background-img" />
      </div>

      <GridColumn px={["xl"]}>
        <div className="family-mediation__content">
          <GridRow justify="center">
            <div className="family-mediation__title cross-title">
              <h2 className="roman-number">II.</h2>
              <div className="title">
                <h2>
                  Familiale
                  <br />
                  bemiddeling
                </h2>
                <img src={LineSVG} alt="line-svg" />
              </div>
            </div>
          </GridRow>

          <div className="family-mediation__description">
            <p>
              Zowel de wetgever als de rechtbank geven meer en meer de voorrang aan familiale
              bemiddeling in plaats van lange (scheidings)procedures. De erkende familiale
              bemiddelaars van het scheidingshuis gaan samen met de partijen op zoek naar een
              akkoord waar beide ex-partners zich goed bij voelen.
            </p>
            <p>Minder procedures, minder kosten en een beter gevoel achteraf.</p>

            <div className="family-mediation__cta">
              <Link to="/family-mediation/">
                <Button>Lees meer</Button>
              </Link>
            </div>
          </div>
        </div>
      </GridColumn>
    </div>
  );
};

export default MobileFamilyMeditation;
