import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import Quote1SVG from "../../../assets/svg/quote1.svg";
import Quote2SVG from "../../../assets/svg/quote2.svg";
import SymbolImage from "../../../assets/images/juridische-begeleiding_symbol.png";

import "./index.scss";

const LegalContent = () => {
  return (
    <div className="legal-content">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 6]} className="legal-content__left">
              <p>
                Wanneer blijkt dat bemiddeling geen soelaas meer kan bieden, kan u beroep doen op
                onze advocaten die gespecialiseerd zijn in familierecht.Scheiding, omgangsregeling
                met kinderen, alimentatie, erkenning, ... zijn voor het scheidingshuis dagelijkse
                praktijk.We garanderen een uitmuntende kennis van ons vakgebied, niet alleen omwille
                van onze jarenlange ervaring in echtscheidingsdossiers, maar ook door de jaarlijkse
                bijscholingen die er voor zorgen dat onze medewerkers steeds op de hoogte zijn van
                de laatste nieuwe ontwikkelingen in het familirecht.
              </p>
              <p className="legal-content__extra legal-content__extra--mobile">
                Een persoonlijke aanpak waarbij we tijd maken om te luisteren naar uw verhaal is
                voor ons cruciaal voor de opbouw van een degelijk dossier.De combinatie van kennis
                en empathie zorgt er voor dat wij ook op rechtbank sterk in onze schoenen staan om u
                en uw zaak te verdedigen!
              </p>

              <div className="legal-content__image">
                <img src={SymbolImage} alt="legal-img" />
                <div className="golden-box" />
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>

      <GridColumn width={[12, 12, 12, 6]} className="legal-content__right">
        <div className="legal-content__review">
          <div className="legal-content__review--inner">
            <GridRow>
              <img src={Quote1SVG} alt="quote-1" />
            </GridRow>
            <h2>
              Door de snelle en duidelijke communicatie, was onze echtscheiding eenvoudig opgelost.
            </h2>
            <GridRow justify="end">
              <img src={Quote2SVG} alt="quote-2" />
            </GridRow>
          </div>
        </div>

        <div className="legal-content__extra legal-content__extra--desktop">
          <div className="legal-content__extra--inner">
            <p>
              Een persoonlijke aanpak waarbij we tijd maken om te luisteren naar uw verhaal is voor
              ons cruciaal voor de opbouw van een degelijk dossier.De combinatie van kennis en
              empathie zorgt er voor dat wij ook op rechtbank sterk in onze schoenen staan om u en
              uw zaak te verdedigen!
            </p>
          </div>
        </div>
      </GridColumn>
    </div>
  );
};

export default LegalContent;
