const menuObj = [
  {
    name: "Home",
    to: "bannerRef",
    id: "bannerMenu",
  },
  {
    name: "Begeleiding",
    to: "legalRef",
    id: "legalMenu",
  },
  {
    name: "Bemiddeling",
    to: "familyRef",
    id: "familyMenu",
  },
  {
    name: "Ontzorging",
    to: "unburdenRef",
    id: "unburdenMenu",
  },
  { name: "Formules", to: "formulaRef", id: "formulaMenu" },
  { name: "Contact", to: "contactRef", id: "contactMenu" },
];

export default menuObj;
