import React from "react";

import { Element } from "react-scroll";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./assets/styles/app.scss";

import theme from "./utils/theme";
import Header from "./components/header";
import Contact from "./components/contact";
import Footer from "./components/footer";

import HomePage from "./pages/home";
import LegalGuidance from "./pages/legal-guidance";
import FamilyMediation from "./pages/family-mediation";
import TotalUnburden from "./pages/total-unburden";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />

          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/legal-guidance/" component={LegalGuidance} />
            <Route exact path="/family-mediation/" component={FamilyMediation} />
            <Route exact path="/total-unburden/" component={TotalUnburden} />
          </Switch>

          <Element name="contactRef">
            <Contact />
          </Element>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
