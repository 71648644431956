import SofieAvatar from "../../../assets/images/Sofie.png";
import WendyAvatar from "../../../assets/images/Wendy.png";
import SaraAvatar from "../../../assets/images/Sara.png";

const teamObj = [
  {
    name: "<h3>Sofie<br/>Van Wesemael</h3>",
    role: "<p>Advocaat &<br/> Erkend familiaal bemiddelaar</p>",
    avatar: SofieAvatar,
    calendlyUrl: "https://calendly.com/info-22976",
  },
  {
    name: "<h3>Wendy<br/>de Herdt</h3>",
    role: "<p>Medewerker &<br/> Dossierbeheerder</p>",
    avatar: WendyAvatar,
    calendlyUrl: "https://calendly.com/info-22976",
  },
  {
    name: "<h3>Sara<br/>Segers</h3>",
    role: "<p>Medewerker &<br/> Dossierbeheerder</p>",
    avatar: SaraAvatar,
    calendlyUrl: "https://calendly.com/info-22976",
  },
];

export default teamObj;
