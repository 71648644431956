import React, { useState, useEffect } from "react";
import { Link, scroller } from "react-scroll";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import { Button } from "../commons";

import { setAppointment } from "../../redux/actions/contact.action";

import AnimateText from "../animate-text";
import Logo from "../../assets/svg/logo.svg";
import WhiteLogo from "../../assets/svg/logo-white.svg";

import "./index.scss";

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [customHeader, setCustomHeader] = useState(true);

  useEffect(() => {
    if (!location || !location.pathname) return;

    setCustomHeader(location.pathname !== "/");
  }, [location]);

  const toFormulaSection = () => {
    if (location.pathname !== "/") history.push("/", { toFormula: true });
    else {
      scroller.scrollTo("formulaRef", {
        duration: 1000,
        delay: 100,
        smooth: true,
      });
    }
  };

  return (
    <div className={`header ${customHeader && "header--custom"}`}>
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow justify="between">
            <a href="/">
              <div className="nav-brand">
                {customHeader ? (
                  <img src={WhiteLogo} alt="white-logo" />
                ) : (
                  <img src={Logo} alt="logo" />
                )}
              </div>
            </a>

            <div className="nav">
              <div className="nav__item">
                <Link
                  to="contactRef"
                  smooth={true}
                  duration={1000}
                  onClick={() => dispatch(setAppointment(false))}
                >
                  <AnimateText label="Contact" white={customHeader} />
                </Link>
              </div>

              <div className="nav__item">
                <AnimateText
                  label="Onze formules"
                  white={customHeader}
                  onClick={toFormulaSection}
                />
              </div>

              <div className="nav__item nav__item--desktop">
                <a href="https://calendly.com/info-22976" target="_blank">
                  <Button color="primary">Maak een afspraak</Button>
                </a>
              </div>
            </div>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};
export default Header;
