import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import MobileBanner from "./mobile-banner";
import WomanImage from "../../../assets/images/header.png";

import "./index.scss";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner__desktop">
        <GridWrap>
          <GridColumn px={["xl"]}>
            <GridRow wrap="wrap">
              <GridColumn width={[5, 5, 5, 6]}>
                <h1>
                  Relatie.
                  <br />
                  Breuk.
                  <br /> Begeleiding.
                </h1>
              </GridColumn>

              <GridColumn width={[7, 7, 7, 6]}>
                <div className="image-wrapper">
                  <img src={WomanImage} alt="woman-img" />
                  <div className="golden-box" />
                </div>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridWrap>
      </div>

      <MobileBanner />
    </div>
  );
};

export default Banner;
