import React from "react";

import "./index.scss";

const AnimateText = ({ label, white, ...props }) => {
  return (
    <div className={`animate-text ${white && "animate-text--white"}`} {...props}>
      <p>{label}</p>
    </div>
  );
};

export default AnimateText;
