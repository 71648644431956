import React from "react";
import { Link } from "react-scroll";
import { useDispatch } from "react-redux";
import { GridColumn, GridRow } from "emotion-flex-grid";

import { Button } from "../../../components/commons";
import { setAppointment } from "../../../redux/actions/contact.action";

import WomanImage from "../../../assets/images/header.png";

const MobileBanner = () => {
  const dispatch = useDispatch();

  return (
    <div className="banner__mobile">
      <GridRow wrap="wrap">
        <GridColumn width={[5, 5, 5, 6]} className="banner__mobile--col">
          <h1>
            Relatie.
            <br />
            Breuk.
            <br /> Begeleiding.
            <a href="https://calendly.com/info-22976" target="_blank">
              <Button color="primary">Maak een afspraak</Button>
            </a>
          </h1>
          <div className="golden-box" />
        </GridColumn>

        <GridColumn width={[7, 7, 7, 6]}>
          <div className="image-wrapper">
            <img src={WomanImage} alt="woman-img" />
            <div className="golden-box" />
          </div>
        </GridColumn>
      </GridRow>
    </div>
  );
};

export default MobileBanner;
