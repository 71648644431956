import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import Quote1SVG from "../../../assets/svg/quote1.svg";
import Quote2SVG from "../../../assets/svg/quote2.svg";

import "./index.scss";

const Quote = () => {
  return (
    <div className="quote">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 6]} pl={["none", "none", "none", "xl"]}>
              <GridRow>
                <img src={Quote1SVG} alt="quote-1" />
              </GridRow>

              <h2>Er werd naar me geluisterd en alles is gedaan om mijn verhaal te brengen.</h2>

              <GridRow justify="end">
                <img src={Quote2SVG} alt="quote-2" />
              </GridRow>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default Quote;
