import { Types } from "../actions/contact.action";

const initialState = {
  isAppointment: false,
  isHomeContact: true,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_APPOINTMENT:
      return {
        ...state,
        isAppointment: action.payload,
      };

    case Types.SET_HOME_CONTACT_SECTION:
      return {
        ...state,
        isHomeContact: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;
