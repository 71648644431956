import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { animateScroll as scroll, Element } from "react-scroll";

import { setAppointment, setHomeContactSection } from "../../redux/actions/contact.action";

import Hero from "./hero";
import FamilyContent from "./content";
import PageRedirect from "./page-redirect";

const FamilyMediation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppointment(true));
    dispatch(setHomeContactSection(false));

    scroll.scrollToTop();
  }, []);

  return (
    <>
      <Hero />
      <Element name="mediationContentRef">
        <FamilyContent />
      </Element>
      <PageRedirect />
    </>
  );
};

export default FamilyMediation;
