import React from "react";
import { Link } from "react-scroll";
import { useDispatch } from "react-redux";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import { setAppointment } from "../../../redux/actions/contact.action";

import { Button } from "../../../components/commons";
import AnimateText from "../../../components/animate-text";

import LineSVG from "../../../assets/svg/line.svg";
import MobileOntImage from "../../../assets/images/mobile-ontzorging.png";
import DesktopOntImage from "../../../assets/images/desktop-ontzorging.png";

import "./index.scss";

const Unburdening = () => {
  const dispatch = useDispatch();

  return (
    <div className="unburden" id="unburden">
      <div className="unburden-image unburden-image__mobile">
        <img src={MobileOntImage} alt="ontzorging-img" />
      </div>

      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 6]}>
              <div className="unburden-image unburden-image__desktop">
                <img src={DesktopOntImage} alt="ontzorging-img" />
              </div>
            </GridColumn>

            <GridColumn width={[12, 12, 12, 6]}>
              <div className="unburden__content">
                <div className="unburden__title cross-title">
                  <h2 className="roman-number">III.</h2>
                  <div className="title">
                    <h2>
                      Totale
                      <br />
                      ontzorging
                    </h2>
                    <img src={LineSVG} alt="line-svg" />
                  </div>
                </div>

                <div className="unburden__description">
                  <p>
                    Bij een relatiebreuk wordt men geconfronteerd met heel wat
                    vragen en onzekerheid.
                  </p>
                  <p>
                    Wij willen u een totaaloplossing bieden, waar uw
                    dossierbeheerder en vaste advocaat een directe lijn zijn
                    voor al uw vragen. We gaan graag samen met u, stap voor stap
                    door het traject waar we kunnen rekenen op onze vertrouwde
                    partners uit het veld.
                  </p>

                  <GridRow className="unburden__cta" align="center">
                    <a href="/total-unburden/">
                      <AnimateText label="Lees meer" />
                    </a>

                    <a href="/total-unburden/">
                      <div className="unburden__cta--mobile">
                        <Button>Lees meer</Button>
                      </div>
                    </a>

                    <a
                      href="https://calendly.com/info-22976"
                      target="_blank"
                    >
                      <div className="unburden__cta--desktop">
                        <Button>Maak een afspraak</Button>
                      </div>
                    </a>
                  </GridRow>
                </div>
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default Unburdening;
