import React from "react";
import Select, { components } from "react-select";

import DropDownSVG from "../../../assets/svg/dropdown.svg";

import "./index.scss";

const CustomSelect = ({ options, valueContainer, placeholder, ...props }) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={DropDownSVG} alt="dropdown-icon" />
        </components.DropdownIndicator>
      )
    );
  };

  const ValueContainer = valueContainer;

  return (
    <div className="custom-select">
      <Select
        className="selector"
        classNamePrefix="selector"
        options={options}
        components={{ DropdownIndicator, ValueContainer }}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default CustomSelect;
