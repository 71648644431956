import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { GridWrap, GridColumn } from "emotion-flex-grid";

import HeroDesktopImage from "../../../assets/images/totale-ontzorging_header.jpg";
import HeroMobileImage from "../../../assets/images/totale-ontzorging_mobile.png";
import LineSVG from "../../../assets/svg/line-white.svg";
import ArrowDownSVG from "../../../assets/svg/arrow-down.svg";
import ArrowSVG from "../../../assets/svg/arrow.svg";

import "./index.scss";

const Hero = () => {
  const [heroBackImage, setHeroBackImage] = useState(HeroDesktopImage);

  useEffect(() => {
    if (window.innerWidth < 768) setHeroBackImage(HeroMobileImage);
  }, []);

  return (
    <div className="unburden-hero" style={{ backgroundImage: `url(${heroBackImage})` }}>
      <GridWrap className="unburden-hero__wrapper">
        <GridColumn px={["xl"]}>
          <div className="arrow-icon">
            <Link to="unburdenContentRef" smooth={true} duration={500}>
              <img src={ArrowSVG} alt="arrow-icon" />
            </Link>
          </div>

          <div className="unburden-hero__number">
            <h1 className="xl">III.</h1>
          </div>

          <div className="unburden-hero__title cross-title">
            <div className="title">
              <h1>Totale ontzorging</h1>
              <img src={LineSVG} alt="line-svg" />
            </div>
          </div>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default Hero;
