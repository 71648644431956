import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation, useHistory } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import Banner from "./banner";
import LegalGuide from "./legal-guide";
import FamilyMediation from "./family-mediation";
import Quote from "./quote";
import Unburdening from "./unburdening";
import OurFormula from "./our-formula";
import FloatQuote from "./float-quote";
import OurTeam from "./our-team";
import ContactModal from "../../components/contact-modal";
import FloatSidebar from "../../components/float-sidebar";

import "./index.scss";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (location.state && location.state.toFormula) {
      scroller.scrollTo("formulaRef", {
        duration: 1000,
        delay: 1000,
        smooth: true,
      });

      history.replace("/", {});
      return;
    }
  }, [location]);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  return (
    <div className="homepage">
      <Element name="bannerRef">
        <Banner />
      </Element>
      <Element name="legalRef">
        <LegalGuide />
      </Element>
      <Element name="familyRef">
        <FamilyMediation />
      </Element>
      <Quote />

      <Element name="unburdenRef">
        <Unburdening />
      </Element>

      <Element name="formulaRef">
        <OurFormula openModal={() => setModalOpen(true)} />
      </Element>

      <FloatQuote />
      <OurTeam />

      {isModalOpen && (
        <ContactModal isModalOpen={isModalOpen} closeModal={() => setModalOpen(false)} />
      )}

      <FloatSidebar />
    </div>
  );
};

export default Home;
