import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import Quote1SVG from "../../../assets/svg/quote1.svg";
import Quote2SVG from "../../../assets/svg/quote2.svg";

import "./index.scss";

const FloatQuote = () => {
  return (
    <div className="float-quote">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 5]} pr={["none", "none", "none", "xl"]}>
              <div className="float-quote__wrapper">
                <GridRow>
                  <img src={Quote1SVG} alt="quote-1" />
                </GridRow>

                <h2>
                  Door de snelle en duidelijke communicatie, was onze echtscheiding eenvoudig
                  opgelost.
                </h2>

                <GridRow justify="end">
                  <img src={Quote2SVG} alt="quote-2" />
                </GridRow>
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default FloatQuote;
