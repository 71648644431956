import React from "react";
import { Link } from "react-router-dom";
import { GridColumn, GridRow } from "emotion-flex-grid";

import { Button } from "../../../components/commons";

import LineSVG from "../../../assets/svg/line.svg";
import MobileLegalManImage from "../../../assets/images/mobile-legal-man.png";

import "./index.scss";

const LegalGuide = () => {
  return (
    <div className="legal-guide__mobile">
      <GridRow wrap="wrap">
        <GridColumn width={[5, 5, 5, 6]}>
          <div className="image-wrapper">
            <img src={MobileLegalManImage} alt="legal-img" />
          </div>
        </GridColumn>

        <GridColumn width={[7, 7, 7, 6]}>
          <div className="legal-guide__content">
            <div className="legal-guide__title cross-title">
              <h2 className="roman-number">I.</h2>
              <div className="title">
                <h2>
                  Juridische
                  <br />
                  begeleiding
                </h2>
                <img src={LineSVG} alt="line-svg" />
              </div>
            </div>

            <div className="legal-guide__description">
              <p>
                In het scheidingshuis staan onze familierecht-advocaten klaar om u bij te staan in
                al uw familiale procedures.
              </p>
              <p>
                Met onze jarenlange ervaring in scheidingprocedures, conflicten over ouderschap en
                vereffening-verdeling van de huwgemeenschap zijn onze advocaten meer dan wie ook, de
                geschikte partner voor u.
              </p>
              <p>Kennis, duidelijkheid en empathie gaan hier hand in hand!</p>

              <div className="legal-guide__cta">
                <Link to="/legal-guidance/">
                  <Button>Lees meer</Button>
                </Link>
              </div>
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </div>
  );
};

export default LegalGuide;
