import React from "react";
import { Link } from "react-scroll";
import { useDispatch } from "react-redux";

import { Button } from "../../../components/commons";
import { setAppointment } from "../../../redux/actions/contact.action";

const MemberCard = ({ member }) => {
  const dispatch = useDispatch();

  return (
    <div className="member-card">
      <div className="member-card__image">
        <img src={member.avatar} alt="member-img" />
      </div>
      <div
        className="member-card__name"
        dangerouslySetInnerHTML={{ __html: member.name }}
      ></div>
      <div
        className="member-card__role"
        dangerouslySetInnerHTML={{ __html: member.role }}
      ></div>
      <a href={member.calendlyUrl} target="_blank">
        <Button>Maak een afspraak</Button>
      </a>
    </div>
  );
};

export default MemberCard;
