import React from "react";
import { Link } from "react-router-dom";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import ChevronTitle from "../../../components/chevron-title";

import "./index.scss";

const PageRedirect = () => {
  return (
    <div className="page-redirect">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap" justify="between">
            <Link to="/total-unburden/">
              <ChevronTitle>
                <h3>
                  Totale
                  <br />
                  ontzorging
                </h3>
              </ChevronTitle>
            </Link>

            <Link to="/family-mediation/">
              <ChevronTitle right>
                <h3>
                  Familiale
                  <br />
                  bemiddeling
                </h3>
              </ChevronTitle>
            </Link>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </div>
  );
};

export default PageRedirect;
