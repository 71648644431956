import * as emailjs from "emailjs-com";

// YOUR EMAIL.JS API KEY
const API_KEY = "user_NHjVs5XX06aBqRn456NHI";
// YOUR EMAIL.JS TEMPLATE ID
const TEMPLATE_ID = "hetscheidingshuiscontact";

export const sendEmail = (template_params) => {
    const defaultParams = {
        emailAppointmentPerson: 'info@hetscheidingshuis.be',
        message: 'Ik wil graag meer informatie over één van uw formules.',
        appointmentPerson: 'Sofie'
    }

    const currentParams = {
        ...defaultParams,
        ...template_params
    }

    emailjs.send("default_service", TEMPLATE_ID, currentParams, API_KEY).then(
        function (response) {
            if (response.status === 200) {
                console.log('Email Sent!');
            } else {
            }
        },
        () => {}
    );
};
