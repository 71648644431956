export const personOptions = [
  {
    value: "Sofie",
    label: "Sofie",
  },
  {
    value: "Wendy",
    label: "Wendy",
  },
  {
    value: "Sara",
    label: "Sara",
  },
];

export const timeOptions = [
  {
    value: "09:00",
    label: "09:00",
  },
  {
    value: "09:30",
    label: "09:30",
  },
  {
    value: "10:00",
    label: "10:00",
  },
  {
    value: "10:30",
    label: "10:30",
  },
  {
    value: "11:00",
    label: "11:00",
  },
  {
    value: "11:30",
    label: "11:30",
  },
  {
    value: "12:00",
    label: "12:00",
  },
  {
    value: "12:30",
    label: "12:30",
  },
  {
    value: "13:00",
    label: "13:00",
  },
  {
    value: "13:30",
    label: "13:30",
  },
  {
    value: "14:00",
    label: "14:00",
  },
  {
    value: "15:30",
    label: "15:30",
  },
  {
    value: "16:00",
    label: "16:00",
  },
  {
    value: "16:30",
    label: "16:30",
  },
  {
    value: "17:00",
    label: "17:00",
  },
];
