import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import Quote1SVG from "../../../assets/svg/quote1.svg";
import Quote2SVG from "../../../assets/svg/quote2.svg";
import SymbolImage from "../../../assets/images/familiale-begeleiding_symbol.png";

import "./index.scss";

const FamilyContent = () => {
  return (
    <div className="family-content">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 6]} className="family-content__left">
              <p>
                Willen jullie graag zelf de controle nemen over jullie familiaal conflict en samen
                een oplossing zoeken, waar alle partijen achter kunnen staan?
              </p>
              <p>Dan is familiale bemiddeling iets voor jullie.</p>
              <p className="family-content__extra family-content__extra--mobile">
                De erkende familiale bemiddelaars van het scheidingshuis hebben jarenlange ervaring
                in het begeleiden van ex-partners tijdens gesprekken en het vinden van een akkoord.
                Onderzoek wijst uit dat bemiddeling in meer dan 85% van de gevallen leidt tot een
                overeenkomst tussen partijen. Dit zorgt voor minder procedures, minder conflict en
                een mooie afsluiting van een vaak moeilijke periode.
              </p>

              <div className="family-content__image">
                <img src={SymbolImage} alt="legal-img" />
                <div className="golden-box" />
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>

      <GridColumn width={[12, 12, 12, 6]} className="family-content__right">
        <div className="family-content__review">
          <div className="family-content__review--inner">
            <GridRow>
              <img src={Quote1SVG} alt="quote-1" />
            </GridRow>
            <h2>
              Een akkoord leek ver weg, maar door de bemiddeling zijn we er toch samen uitgeraakt.
            </h2>
            <GridRow justify="end">
              <img src={Quote2SVG} alt="quote-2" />
            </GridRow>
          </div>
        </div>

        <div className="family-content__extra family-content__extra--desktop">
          <div className="family-content__extra--inner">
            <p>
              De erkende familiale bemiddelaars van het scheidingshuis hebben jarenlange ervaring in
              het begeleiden van ex-partners tijdens gesprekken en het vinden van een akkoord.
              Onderzoek wijst uit dat bemiddeling in meer dan 85% van de gevallen leidt tot een
              overeenkomst tussen partijen. Dit zorgt voor minder procedures, minder conflict en een
              mooie afsluiting van een vaak moeilijke periode.
            </p>
          </div>
        </div>
      </GridColumn>
    </div>
  );
};

export default FamilyContent;
