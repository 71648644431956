import React from "react";
import { GridRow, GridColumn } from "emotion-flex-grid";

import { Button } from "../../../components/commons";

const FormulaCard = ({ formula, index, openModal }) => {
  return (
    <div className="formula-card">
      <GridColumn className="formula-card__detail" textAlign="center">
        <img src={formula.icon} alt="formula-icon" />
        <span className="formula-card__no">{`Pakket ${index}`}</span>
        <p className="xxl">{formula.title}</p>

        <ul className="formula-card__details">
          {formula.features.map((feature, id) => (
            <li key={id}>{feature}</li>
          ))}
        </ul>
      </GridColumn>

      <div className="formula-card__price">
        <GridRow className="formula-card__price--single" justify="center">
          <p className="xl">{`€${formula.pricePerPerson}`}</p>
          <span className="sm">Per persoon</span>
        </GridRow>
        <GridRow className="formula-card__price--single" justify="center">
          <p>+{`€${formula.priceKinderen}`}</p>
          <span className="sm">P.P., met kinderen</span>
        </GridRow>
        <GridRow className="formula-card__price--single" justify="center">
          <p>+{`€${formula.priceGoed}`}</p>
          <span className="sm">P.P., met gezamenlijk onroerend goed</span>
        </GridRow>
      </div>

      <div className="formula-card__contact">
        <Button onClick={openModal}>Neem contact op</Button>
      </div>
    </div>
  );
};

export default FormulaCard;
