import React, { useState } from "react";

import "./index.scss";

const Input = ({ label, value, color, ...props }) => {
  const [active, setActive] = useState(value && value.length > 0);

  const onFocus = () => {
    setActive(true);
  };

  const onBlur = (event) => {
    setActive(event.target.value.length !== 0);
  };

  return (
    <div className={`input-wrapper ${color === "white" && "input-wrapper--white"}`}>
      <label className={`${active && "label-active"}`}>{label}</label>
      <input value={value} onBlur={onBlur} onFocus={onFocus} {...props} />
    </div>
  );
};

export default Input;
