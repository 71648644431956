import React from "react";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import Quote1SVG from "../../../assets/svg/quote1.svg";
import Quote2SVG from "../../../assets/svg/quote2.svg";
import SymbolImage from "../../../assets/images/totale-ontzorging_symbol.png";

import "./index.scss";

const UnburdenContent = () => {
  return (
    <div className="unburden-content">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow wrap="wrap">
            <GridColumn width={[12, 12, 12, 6]} className="unburden-content__left">
              <p>
                Bij het scheidinghuis vinden wij het niet alleen belangrijk om de juridische
                afhandeling van uw relatiebreuk te begeleiden, maar ook om u een totaal oplossing
                van A tot Z aan te bieden.
              </p>
              <p>
                Wij beseffen immers maar al te goed hoe intens de gevolgen van een relatiebreuk
                kunnen zijn, zowel emotioneel, financieel als administratief. Wij willen dan ook u
                zo veel mogelijk van de zorgen ontnemen.
              </p>
              <p className="unburden-content__extra unburden-content__extra--mobile">
                De erkende familiale bemiddelaars van het scheidingshuis hebben jarenlange ervaring
                in het begeleiden van ex-partners tijdens gesprekken en het vinden van een akkoord.
                Onderzoek wijst uit dat bemiddeling in meer dan 85% van de gevallen leidt tot een
                overeenkomst tussen partijen. Dit zorgt voor minder procedures, minder conflict en
                een mooie afsluiting van een vaak moeilijke periode.
              </p>

              <div className="unburden-content__image">
                <img src={SymbolImage} alt="legal-img" />
                <div className="golden-box" />
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>

      <GridColumn width={[12, 12, 12, 6]} className="unburden-content__right">
        <div className="unburden-content__review">
          <div className="unburden-content__review--inner">
            <GridRow>
              <img src={Quote1SVG} alt="quote-1" />
            </GridRow>
            <h2>Er werd naar me geluisterd en alles is gedaan om mijn verhaal te brengen.</h2>
            <GridRow justify="end">
              <img src={Quote2SVG} alt="quote-2" />
            </GridRow>
          </div>
        </div>

        <div className="unburden-content__extra unburden-content__extra--desktop">
          <div className="unburden-content__extra--inner">
            <p>
              Door onze jarenlange ervaring binnen ons vakgebied heeft het scheidingshuis een ruim
              en waardevol netwerk van experts uitgebouwd die we rechtstreeks kunnen aanspreken voor
              u.
            </p>
            <p>
              Uw dossierbeheerder is uw aanspreekpunt en zal als tussenpersoon optreden voor
              contacten met deskundigen, gerechtsdeurwaarders, notarissen, schatters-landmeters,
              immo-kantoren of psychologen.
            </p>
          </div>
        </div>
      </GridColumn>
    </div>
  );
};

export default UnburdenContent;
