import React from "react";
import Slider from "react-slick";
import { GridWrap, GridColumn } from "emotion-flex-grid";

import MemberCard from "./member-card";
import teamObj from "./constant";
import LineSVG from "../../../assets/svg/line.svg";

import "./index.scss";

const OurTeam = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          dots: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 560,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          dots: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <div className="our-team">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <div className="our-team__title cross-title">
            <div className="title">
              <h2>Het team</h2>
              <img src={LineSVG} alt="line-svg" />
            </div>
          </div>
        </GridColumn>

        <div className="our-team__wrapper">
          <Slider {...settings}>
            {teamObj.map((member, id, url) => (
              <MemberCard key={id} member={member} url={url} />
            ))}
          </Slider>
          <div className="golden-box" />
        </div>
      </GridWrap>
    </div>
  );
};

export default OurTeam;
