import React from "react";
import { Link } from "react-scroll";
import { useDispatch } from "react-redux";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

import { setAppointment } from "../../../redux/actions/contact.action";

import { Button } from "../../../components/commons";
import AnimateText from "../../../components/animate-text";
import MobileLegalGuide from "./mobile-legal-guide";

import LegalManImage from "../../../assets/images/desktop-legal-man.png";
import LineSVG from "../../../assets/svg/line.svg";

import "./index.scss";

const LegalGuide = () => {
  const dispatch = useDispatch();

  return (
    <div className="legal-guide">
      <div className="legal-guide__desktop">
        <GridWrap>
          <GridColumn px={["xl"]}>
            <GridRow wrap="wrap">
              <GridColumn width={[5, 5, 5, 6]}>
                <div className="image-wrapper">
                  <img src={LegalManImage} alt="legal-img" />
                </div>
              </GridColumn>

              <GridColumn width={[7, 7, 7, 6]}>
                <div className="legal-guide__content">
                  <div className="legal-guide__title cross-title">
                    <h2 className="roman-number">I.</h2>
                    <div className="title">
                      <h2>
                        Juridische
                        <br />
                        begeleiding
                      </h2>
                      <img src={LineSVG} alt="line-svg" />
                    </div>
                  </div>

                  <div className="legal-guide__description">
                    <p>
                      In het scheidingshuis staan onze familierecht-advocaten
                      klaar om u bij te staan in al uw familiale procedures.
                    </p>
                    <p>
                      Met onze jarenlange ervaring in scheidingprocedures,
                      conflicten over ouderschap en vereffening-verdeling van de
                      huwgemeenschap zijn onze advocaten meer dan wie ook, de
                      geschikte partner voor u.
                    </p>
                    <p>
                      Kennis, duidelijkheid en empathie gaan hier hand in hand!
                    </p>

                    <GridRow className="legal-guide__cta" align="center">
                      <a href="/legal-guidance/">
                        <AnimateText label="Lees meer" />
                      </a>
                      <a
                        href="https://calendly.com/info-22976"
                        target="_blank"
                      >
                        <Button>Maak een afspraak</Button>
                      </a>
                    </GridRow>
                  </div>
                </div>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridWrap>
      </div>

      <MobileLegalGuide />
    </div>
  );
};

export default LegalGuide;
