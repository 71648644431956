import React from "react";
import Slider from "react-slick";
import { GridWrap, GridColumn } from "emotion-flex-grid";
import FormulaCard from "./formula-card";

import LineSVG from "../../../assets/svg/line-white.svg";
import formulaObj from "./constant";

import "./index.scss";

const OurFormula = ({ openModal }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          dots: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 560,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          dots: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <div className="our-formula" id="our-formula">
      <GridWrap>
        <GridColumn px={["xl"]}>
          <div className="our-formula__title cross-title">
            <div className="title">
              <h2>Onze formules</h2>
              <img src={LineSVG} alt="line-svg" />
            </div>
          </div>
        </GridColumn>

        <Slider {...settings}>
          {formulaObj.map((formula, id) => (
            <FormulaCard key={id} index={id + 1} formula={formula} openModal={openModal} />
          ))}
        </Slider>
      </GridWrap>
    </div>
  );
};

export default OurFormula;
