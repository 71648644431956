import React from "react";
import { ClassNames } from "@emotion/react";
import SendSVG from "../../../assets/svg/send-icon.svg";

import "./index.scss";

const Button = ({
  className,
  color = "outline-primary",
  children,
  fullWidth,
  hasIcon,
  ...props
}) => {
  let classList = ["btn"];

  if (className) {
    classList.push(className);
  }
  if (fullWidth) {
    classList.push("btn-full");
  }

  switch (color) {
    case "primary":
      classList.push("btn-primary");
      break;
    case "white":
      classList.push("btn-white");
      break;
    case "outline-primary":
      classList.push("btn-outline-primary");
      break;
    default:
      break;
  }

  return (
    <ClassNames>
      {({ cx }) => (
        <div className={classList.join(" ")} {...props}>
          <button>
            {hasIcon && <img src={SendSVG} alt="send-icon" />}
            {children}
          </button>
          <div className="right-shape" />
        </div>
      )}
    </ClassNames>
  );
};

export default Button;
