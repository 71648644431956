import Icon1SVG from "../../../assets/svg/online-scheiden.svg";
import Icon2SVG from "../../../assets/svg/broken-heart.svg";
import Icon3SVG from "../../../assets/svg/user-group.svg";

const formulaObj = [
  {
    icon: Icon1SVG,
    title: "Online scheiden",
    features: ["Gesprek met dossierbeheerder", "Opmaak regelingsakte", "Opmaak verzoekschrift"],
    pricePerPerson: 290,
    priceKinderen: 90,
    priceGoed: 90,
  },
  {
    icon: Icon2SVG,
    title: "Relatiebreuk- begeleiding",
    features: [
      "Gesprek met familierechtadvocaat",
      "Opvolging door persoonlijke dossierbeheerder",
      "Opvraging van alle nodige aktes en attesten",
      "Regelingsakte en/of verzoekschrift op maat",
      "Nabespreking dossierbeheerder",
      "Alle communicatie en verschijningen voor rechtbank",
    ],
    pricePerPerson: 490,
    priceKinderen: 150,
    priceGoed: 150,
  },
  {
    icon: Icon3SVG,
    title: "Relatiebreuk- begeleiding met bemiddeling",
    features: [
      "3 bemiddelingssessies met erkende familiale bemiddelaar",
      "Opvolging door persoonlijke dossierbeheerder",
      "Opvraging van alle nodige aktes en attesten",
      "Regelingsakte en/of verzoekschrift op maat",
      "Ouderschapsplan op maat",
      "Nabespreking familierechtadvocaat",
      "Alle communicatie en verschijningen voor rechtbank",
    ],
    pricePerPerson: 690,
    priceKinderen: 150,
    priceGoed: 150,
  },
];

export default formulaObj;
