import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import HouseSVG from "../../assets/svg/house-icon.svg";
import HouseWhiteSVG from "../../assets/svg/house-icon-white.svg";

import menuObj from "./constant";

import "./index.scss";

const SideMenuItem = ({ item }) => {
  const [itemWhite, setItemWhite] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const itemElement = document.getElementById(item.id);
      const itemOffsetTop = itemElement && itemElement.offsetTop;
      const itemOffsetHeight = itemElement && itemElement.offsetHeight;

      const formulaElement = document.getElementById("our-formula");
      const formulaOffsetTop = formulaElement && formulaElement.offsetTop;
      const formulaOffsetHeight = formulaElement && formulaElement.offsetHeight;

      if (itemOffsetTop === null || formulaOffsetTop === null) return;

      if (
        window.pageYOffset + itemOffsetTop + itemOffsetHeight + 181 + 75 >= formulaOffsetTop &&
        window.pageYOffset + itemOffsetTop + itemOffsetHeight + 181 + 75 <=
          formulaOffsetTop + formulaOffsetHeight
      ) {
        setItemWhite(true);
      } else {
        setItemWhite(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, [item.id]);

  return (
    <li id={item.id} className={`${itemWhite ? "item--white" : ""}`}>
      <Link activeClass="active" spy={true} smooth={true} duration={500} to={item.to}>
        {item.name}
      </Link>
      {itemWhite ? (
        <img src={HouseWhiteSVG} alt="house-icon" />
      ) : (
        <img src={HouseSVG} alt="house-icon" />
      )}
    </li>
  );
};

const FloatSidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const contactElement = document.getElementById("contact-us");
      const offsetTop = contactElement && contactElement.offsetTop;
      const offsetHeight = contactElement && contactElement.offsetHeight;

      if (offsetTop === null) return;

      if (window.pageYOffset < 50) {
        setSidebarVisible(false);
      } else {
        if (window.pageYOffset >= offsetTop - offsetHeight / 2) {
          setSidebarVisible(false);
        } else {
          setSidebarVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`float-sidebar ${!sidebarVisible && "float-sidebar--hidden"}`}>
      <ul>
        {menuObj.map((el, id) => (
          <SideMenuItem key={id} item={el} />
        ))}
      </ul>
    </div>
  );
};

export default FloatSidebar;
