import React, { useState } from "react";

import ChevronRightSVG from "../../assets/svg/chevron-right.svg";
import ChevronLongRightSVG from "../../assets/svg/chevron-long-right.svg";

import "./index.scss";

const ChevronTitle = ({ right, children }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`chevron-title ${right && "chevron-title--right"}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {hover ? (
        <img
          src={ChevronLongRightSVG}
          alt="chevron-icon"
          className="chevron-long"
          style={{ transform: `${right ? "0" : "rotate(180deg)"}` }}
        />
      ) : (
        <img
          src={ChevronRightSVG}
          alt="chevron-icon"
          style={{ transform: `${right ? "0" : "rotate(180deg)"}` }}
        />
      )}
    </div>
  );
};

export default ChevronTitle;
