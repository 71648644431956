import React, { useState } from "react";
import Modal from "react-modal";
import { GridRow } from "emotion-flex-grid";

import { Button, Input } from "../commons";
import { sendEmail } from "../../utils/email";

import LineSVG from "../../assets/svg/line-white.svg";
import CloseSVG from "../../assets/svg/close-icon.svg";

import "./index.scss";

const ContactModal = ({ isModalOpen, closeModal }) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: "1000",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      maxWidth: "1120px",
      margin: "30px auto",
      width: "84%",
      background: "#83A07F",
      transform: "translate(-50%, -50%)",
      overflow: "initial",
      padding: "0",
    },
  };

  const [subscribe, setSubscribe] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event, type) => {
    event.preventDefault();

    if (type === "name") {
      setContact({
        ...contact,
        name: event.target.value,
      });
    } else if (type === "email") {
      setContact({
        ...contact,
        email: event.target.value,
      });
    }
  };

  const handleSubmit = () => {
    let template_params = {
      name: contact.name,
      email: contact.email,
    };

    sendEmail(template_params);
    setSubscribe(true);
  };

  return (
    <Modal
      closeTimeoutMS={2000}
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Contact Modal"
    >
      <div className="contact-modal">
        <GridRow className="close-icon" onClick={closeModal}>
          <img src={CloseSVG} alt="close-icon" />
        </GridRow>

        <div className="contact-modal__wrapper">
          <div className="contact-modal__title cross-title">
            <div className="title">
              <h1>Ik wens meer informatie</h1>
              <img src={LineSVG} alt="line-svg" />
            </div>
          </div>

          <div className="contact-form">
            <Input
              label="Naam"
              color="white"
              value={contact.name}
              onChange={(e) => handleChange(e, "name")}
            />
            <Input
              label="Email adres"
              color="white"
              value={contact.email}
              onChange={(e) => handleChange(e, "email")}
            />
            <GridRow justify="end">
              <Button color="white" onClick={handleSubmit} hasIcon={subscribe}>
                {subscribe ? "Verzonden" : "Verstuur"}
              </Button>
            </GridRow>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
