import React from "react";
import { Link } from "react-router-dom";
import { GridWrap, GridColumn, GridRow } from "emotion-flex-grid";

// import SocialLinks from "../social-links";

import Logo from "../../assets/svg/logo-white.svg";
import FooterImage from "../../assets/images/footer.png";

import "./index.scss";

const Footer = () => {
  return (
    <footer>
      <GridWrap>
        <GridColumn px={["xl"]}>
          <GridRow className="footer">
            <GridColumn width={[12, 12, 12, 7, 6]} className="footer__info">
              <div className="footer__brand">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>

              {/* <SocialLinks /> */}

              <div className="contact-info">
                <div className="contact-info__item">
                  <span>Adres</span>
                  <p>Van Putlei 120, 2547 Lint</p>
                </div>
                <div className="contact-info__item">
                  <span>Email</span>
                  <a href="mailto:info@hetscheidingshuis.be">
                    <p>info@hetscheidingshuis.be</p>
                  </a>
                </div>
                <div className="contact-info__item">
                  <span>Telefoon</span>
                  <p>
                    03/294 95 42 <br /> 0473/650.321
                  </p>
                </div>
              </div>
            </GridColumn>

            <GridColumn width={[12, 12, 12, 5, 6]} className="footer__image">
              <div className="footer__image--wrapper">
                <img src={FooterImage} alt="footer-img" />
              </div>
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridWrap>
    </footer>
  );
};
export default Footer;
