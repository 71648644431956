import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { animateScroll as scroll, Element } from "react-scroll";

import { setAppointment, setHomeContactSection } from "../../redux/actions/contact.action";

import Hero from "./hero";
import LegalContent from "./content";
import PageRedirect from "./page-redirect";

const LegalGuidance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppointment(true));
    dispatch(setHomeContactSection(false));

    scroll.scrollToTop();
  }, []);

  return (
    <div>
      <Hero />
      <Element name="legalContentRef">
        <LegalContent />
      </Element>
      <PageRedirect />
    </div>
  );
};

export default LegalGuidance;
